<template>
  <div>
    <van-nav-bar :title="name + '的优惠券详情'" />
    <van-cell v-for="(item, index) in data" :key="index" :title="item" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      userId: "",
      name: "",
      data: [],
    };
  },
  methods: {},
  created() {
    this.userId = this.$route.query.userId;
    this.name = this.$route.query.name;
  },
  mounted() {
    this.$axios
      .get(`${this.$base}/fission/page/commission?id=${this.userId}`)
      .then((res) => {
        if (res.code === "200") {
          this.data = res.data;
        }
      });
  },
};
</script>
